<ul class="list-none m-0 px-0 py-3">
    @for (menuItem of menuItems; track menuItem.section) {
    @if (menuItem.showSection) {
    <!-- Section -->
    <li>
        <a class="menu-section-title flex justify-content-between" (click)="onClickSectionTitle(menuItem.section)"
            [routerLink]="appService.menuOpen && !!menuItem.subItems ? null : menuItem.link" routerLinkActive="active-link">
            <div class="with-items" [ngClass]="menuItem.ngClass" [pTooltip]="getTooltip(menuItem)" tooltipPosition="right">
                <i class="fa-solid" [ngClass]="menuItem.icon"></i>
                <span class="flex-grow-1">{{ menuItem.title }}</span>
                @if (menuItem.subItems) {
                <i class="pi" [ngClass]="menuItem.showSubItems ? 'pi-angle-up' : 'pi-angle-down'"></i>
                }
            </div>
        </a>

        <!-- Liens internes à la section -->
        @if (appService.menuOpen && menuItem.showSubItems) {
        @for (subItem of menuItem.subItems; track subItem.link) {
        <a class="menu-item" [routerLink]="subItem.link" routerLinkActive="active-link">
            <div [ngClass]="subItem.ngClass">{{ subItem.title }}
                @if (subItem.locked) {
                <i class="fa-solid fa-lock text-sm ml-2"></i>
                }
            </div>
        </a>
        }
        }
    </li>
    }
    }
</ul>