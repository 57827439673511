import { PurchaseInvoice } from "../purchasing/invoice/purchase-invoice.model";
import { SalesInvoice } from "../sales/invoice/sales-invoice.model";
import { InvoiceBase } from "./abstract/invoice-base.model";
import { InvoicePaymentBase } from "./abstract/invoice-payment-base.model";


export class LegacyPayment extends InvoicePaymentBase {

    static override CLASS_NAME: string = 'LegacyPayment';

    protected _creditNote!: InvoiceBase;
    protected _invoice!: PurchaseInvoice | SalesInvoice;
    private _purchaseInvoice!: PurchaseInvoice;
    private _salesInvoice!: SalesInvoice;

    public get creditNote(): InvoiceBase {
        return this._creditNote;
    }

    public set creditNote(value: InvoiceBase) {
        this._creditNote = value;
    }

    public get invoice(): PurchaseInvoice | SalesInvoice {
        return this._invoice;
    }

    public set invoice(value: InvoiceBase) {
        if (value instanceof PurchaseInvoice) {
            this.purchaseInvoice = value;
            this._setValue('_invoice', value, 'PurchaseInvoice');
        } else if (value instanceof SalesInvoice) {
            this.salesInvoice = value;
            this._setValue('_invoice', value, 'SalesInvoice');
        }
    }

    public get purchaseInvoice(): PurchaseInvoice {
        return this._purchaseInvoice;
    }

    public set purchaseInvoice(value: PurchaseInvoice) {
        this._purchaseInvoice = value;
    }

    public get salesInvoice(): SalesInvoice {
        return this._salesInvoice;
    }

    public set salesInvoice(value: SalesInvoice) {
        this._salesInvoice = value;
    }

}
