import { NgModule, LOCALE_ID, Injector, DEFAULT_CURRENCY_CODE, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import '@angular/common/locales/global/fr';
import { provideFirebaseApp, initializeApp as initializeFirebaseApp } from '@angular/fire/app';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

// Modules
import { CoreModule } from './@core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './home/home.module';

// Composants
import { AppComponent } from './app.component';

// Services
import { AppService } from './@core/services/app.service';
import { UserService } from './@core/services/user/user.service';
import { Logger } from './@core/services/logger/logger.service';

export let AppInjector: Injector;

const LOGGER = new Logger('AppModule');

// Fonction d'initialisation
export function initializeApp(userService: UserService): () => Observable<boolean> {
    LOGGER.debug('Initialisation de l\'application');
    return () => userService.loadUserData$();
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ConfirmDialogModule,
        ToastModule,
        CoreModule,
        AppRoutingModule,
        HomeModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'XPF' },
        provideFirebaseApp(() => initializeFirebaseApp(environment.firebaseConfig)),
        provideMessaging(() => getMessaging()),
        AppService,
        UserService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [UserService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(private _injector: Injector) {
        AppInjector = this._injector;
    }
}
