import moment from "moment";
import { ModelBase } from "../abstract/model-base.model";
import { File } from "../file/file.model";
import { User } from "../user/user.model";

export class AccountingPlanImport extends ModelBase {

    static override CLASS_NAME: string = 'AccountingPlanImport';

    private _creationDate!: moment.Moment;
    private _creationUser!: User;
    private _errorsFile!: File;
    private _inputFile!: File;

    public get creationDate(): moment.Moment {
        return this._creationDate;
    }

    public set creationDate(value: any) {
        this._setValue('_creationDate', value, 'datetime');
    }

    public get creationUser(): User {
        return this._creationUser;
    }

    public set creationUser(value: any) {
        this._setValue('_creationUser', value, 'User');
    }

    public get errorsFile(): File {
        return this._errorsFile;
    }

    public set errorsFile(value: any) {
        this._setValue('_errorsFile', value, 'File');
    }

    public get inputFile(): File {
        return this._inputFile;
    }

    public set inputFile(value: any) {
        this._setValue('_inputFile', value, 'File');
    }
}
