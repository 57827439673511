@if (appReady) {
<router-outlet></router-outlet>
<p-toast position="bottom-right">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column align-items-start" style="flex: 1">
            <div class="flex align-items-center gap-2">
                <span class="font-bold">
                    {{ message.summary }}
                </span>
            </div>
            @if (!!message.detail && message.detail.length > 0) {
            <div class="font-medium my-3">
                {{ message.detail.split('\n')[0] }}
            </div>
            @if (message.detail.split('\n').length > 1) {
            <div class="font-medium text-sm mt-3 mb-1">
                {{ message.detail.split('\n')[1] }}
            </div>
            <div class="font-medium text-sm">
                {{ message.detail.split('\n')[2] }}
            </div>
            }
            }
        </div>
    </ng-template>
</p-toast>
<p-confirmDialog></p-confirmDialog>
} @else {
<div class="w-full h-full flex align-items-center justify-content-center text-xl">
    Chargement en cours...
</div>
}