import { AppConfigModel } from '../abstract/app-config.model';
import { ModelBase } from '../abstract/model-base.model';
import { Account } from '../accounting/account.model';
import { AccountEntry } from '../accounting/account-entry.model';
import { AccountEntryExport } from '../accounting/account-entry-export.model';
import { AccountEntryExportFormat } from '../accounting/account-entry-export-format.model';
import { AccountEntryLine } from '../accounting/account-entry-line.model';
import { AccountEntryNumberRange } from '../accounting/account-entry-number-range.model';
import { AccountEntryType } from '../accounting/account-entry-type.model';
import { AccountStatement } from '../accounting/account-statement.model';
import { AccountStatementTransaction } from '../accounting/account-statement-transaction.model';
import { AccountingMethod } from '../accounting/accounting-method.model';
import { AccountingPeriod } from '../accounting/accounting-period.model';
import { AccountingPlanImport } from '../accounting/accounting-plan-import.model';
import { AccountingSettings } from '../accounting/accounting-settings.model';
import { Address } from '../address/address.model';
import { AddressFormat } from '../address/address-format.model';
import { AllocationType } from '../accounting/allocation-type.model';
import { Authorization } from '../authorization/authorization.model';
import { Bank } from '../banking/bank.model';
import { BankAccount } from '../banking/bank-account.model';
import { BankAccountLine } from '../banking/bank-account-line.model';
import { BankAccountLineAllocation } from '../banking/bank-account-line-allocation.model';
import { BankAccountLineTax } from '../banking/bank-account-line-tax.model';
import { BankAccountLineType } from '../banking/bank-account-line-type.model';
import { BankAccountRule } from '../banking/bank-account-rule.model';
import { BankAccountRuleAllocation } from '../banking/bank-account-line-rule-allocation.model';
import { BusinessEvent } from '../kpi/business-event.model';
import { Cheque } from '../banking/cheque.model';
import { City } from '../address/city.model';
import { Civility } from '../user/civility.model';
import { Client } from '../client/client.model';
import { ClientAccountEntryType } from '../accounting/client-account-entry-type.model';
import { ClientContact } from '../client/contact.model';
import { ClientTax } from '../accounting/client-tax.model';
import { ClientUser } from '../client/client-user.model';
import { Country } from '../core/country.model';
import { Currency } from '../core/currency.model';
import { Deposit } from '../banking/deposit';
import { DiscountType } from '../sales/discount-type.model';
import { Downpayment } from '../sales/invoice/downpayment.model';
import { EcoTaxSector } from '../product/eco-tax-sector.model';
import { Email } from '../messaging/email.model';
import { EmailRedirection } from '../interfaces/email-redirection.model';
import { Employee } from '../human-resources/employee.model';
import { Feature } from '../subscription/feature.model';
import { File } from '../file/file.model';
import { Invite } from '../client/invite.model';
import { InvoiceContact } from '../sales/contact.model';
import { InvoiceNumbering } from '../sales/invoice-numbering.model';
import { InvoiceOrigin } from '../invoicing/invoice-origin.model';
import { InvoiceType } from '../invoicing/invoice-type.model';
import { Journal } from '../accounting/journal.model';
import { KpiBankAccountBalances, KpiBankAccountDailyBalance } from '../kpi/bank-account-balances.model';
import { KpiCharges } from '../kpi/charges.model';
import { KpiMonthlySummary, KpiMonthSummary } from '../kpi/monthly-summary.model';
import { KpiPartnerDebts } from '../kpi/partner-debts.model';
import { LegalStatus } from '../taxation/legal-status.model';
import { LegacyPayment } from '../invoicing/legacy-payment.model';
import { Loan } from '../banking/loan.model';
import { NumericComparator } from '../core/numeric-comparator.model';
import { Organization } from '../legal-entity/organization.model';
import { OrganizationActivity } from '../legal-entity/organization-activity.model';
import { OrganizationIdentifierType } from '../legal-entity/organization-identifier-type.model';
import { OrganizationSize } from '../legal-entity/organization-size.model';
import { Overpayment } from '../banking/overpayment.model';
import { OverpaymentReimbursement } from '../banking/overpayment-reimbursement.model';
import { Package } from '../subscription/package.model';
import { PackageContent } from '../subscription/package-content.model';
import { Partner } from '../partner/partner.model';
import { PartnerAccountStatement } from '../partner/account-statement.model';
import { PartnerContact } from '../partner/contact.model';
import { PartnerGroup } from '../partner/partner-group.model';
import { PartnerIdentifier } from '../partner/partner-identifier.model';
import { PartnerType } from '../partner/partner-type.model';
import { PaymentMethod } from '../core/payment-method.model';
import { PaymentTerm } from '../invoicing/payment-term.model';
import { PhoneAreaCode } from '../address/phone-area-code.model';
import { Pricing } from '../sales/pricing.model';
import { Product } from '../product/product.model';
import { ProductGroup } from '../product/product-group.model';
import { ProductType } from '../product/product-type.model';
import { Profile } from '../authorization/profile.model';
import { PurchaseInvoice } from '../purchasing/invoice/purchase-invoice.model';
import { PurchaseInvoiceAllocation } from '../purchasing/invoice/purchase-invoice-allocation.model';
import { PurchaseInvoiceItem } from '../purchasing/invoice/purchase-invoice-item.model';
import { PurchaseInvoiceItemAllocation } from '../purchasing/invoice/purchase-invoice-item-allocation.model';
import { PurchaseInvoicePayment } from '../purchasing/invoice/purchase-invoice-payment.model';
import { PurchaseInvoiceTax } from '../purchasing/invoice/purchase-invoice-tax.model';
import { Quota } from '../subscription/quota.model';
import { Quotation } from '../sales/quotation/quotation.model';
import { QuotationItem } from '../sales/quotation/quotation-item.model';
import { QuotationTax } from '../sales/quotation/quotation-tax.model';
import { SalesDocumentTemplate } from '../sales/template.model';
import { SalesDocumentType } from '../sales/sales-document-type.model';
import { SalesInvoice } from '../sales/invoice/sales-invoice.model';
import { SalesInvoiceAllocation } from '../sales/invoice/sales-invoice-allocation.model';
import { SalesInvoiceItem } from '../sales/invoice/sales-invoice-item.model';
import { SalesInvoiceItemAllocation } from '../sales/invoice/sales-invoice-item-allocation.model';
import { SalesInvoicePayment } from '../sales/invoice/sales-invoice-payment.model';
import { SalesInvoiceTax } from '../sales/invoice/sales-invoice-tax.model';
import { SalesSettings } from '../sales/sales-settings.model';
import { SellingInfoRecord } from '../sales/selling-info-record.model';
import { Shareholder } from '../client/shareholder.model';
import { Subscription } from '../subscription/subscription.model';
import { Tax } from '../core/tax.model';
import { TaxDetail } from '../core/taxDetail.model';
import { TaxationRegime } from '../taxation/taxation-regime.model';
import { TaxationSettings } from '../taxation/taxation-settings.model';
import { TaxationType } from '../taxation/taxation-type.model';
import { TgcDeclaration } from '../taxation/tgc-declaration.models';
import { Timezone } from '../core/timezone.model';
import { TransactionDirection } from '../banking/transaction-direction.model';
import { Unit } from '../core/unit.model';
import { User } from '../user/user.model';
import { UserProfile } from '../client/user-profile.model';
import { VatChargeability } from '../taxation/vat-chareability.model';
import { VatFrequency } from '../taxation/vat-frequency.model';

export class GeneralFactory {
    static get(className: string, data: any): ModelBase | AppConfigModel {
        switch (className) {
            case 'Account':
                return new Account(data);
            case 'AccountEntry':
                return new AccountEntry(data);
            case 'AccountEntryExport':
                return new AccountEntryExport(data);
            case 'AccountEntryExportFormat':
                return new AccountEntryExportFormat(data);
            case 'AccountEntryLine':
                return new AccountEntryLine(data);
            case 'AccountEntryNumberRange':
                return new AccountEntryNumberRange(data);
            case 'AccountEntryType':
                return new AccountEntryType(data);
            case 'AccountStatement':
                return new AccountStatement(data);
            case 'AccountStatementTransaction':
                return new AccountStatementTransaction(data);
            case 'AccountingMethod':
                return new AccountingMethod(data);
            case 'AccountingPeriod':
                return new AccountingPeriod(data);
            case 'AccountingPlanImport':
                return new AccountingPlanImport(data);
            case 'AccountingSettings':
                return new AccountingSettings(data);
            case 'Address':
                return new Address(data);
            case 'AddressFormat':
                return new AddressFormat(data);
            case 'AllocationType':
                return new AllocationType(data);
            case 'Authorization':
                return new Authorization(data);
            case 'Bank':
                return new Bank(data);
            case 'BankAccount':
                return new BankAccount(data);
            case 'BankAccountLine':
                return new BankAccountLine(data);
            case 'BankAccountLineAllocation':
                return new BankAccountLineAllocation(data);
            case 'BankAccountLineTax':
                return new BankAccountLineTax(data);
            case 'BankAccountLineType':
                return new BankAccountLineType(data);
            case 'BankAccountRule':
                return new BankAccountRule(data);
            case 'BankAccountRuleAllocation':
                return new BankAccountRuleAllocation(data);
            case 'BusinessEvent':
                return new BusinessEvent(data);
            case 'Cheque':
                return new Cheque(data);
            case 'City':
                return new City(data);
            case 'Civility':
                return new Civility(data);
            case 'Client':
                return new Client(data);
            case 'ClientAccountEntryType':
                return new ClientAccountEntryType(data);
            case 'ClientContact':
                return new ClientContact(data);
            case 'ClientTax':
                return new ClientTax(data);
            case 'ClientUser':
                return new ClientUser(data);
            case 'Country':
                return new Country(data);
            case 'Currency':
                return new Currency(data);
            case 'Deposit':
                return new Deposit(data);
            case 'DiscountType':
                return new DiscountType(data);
            case 'Downpayment':
                return new Downpayment(data);
            case 'EcoTaxSector':
                return new EcoTaxSector(data);
            case 'Email':
                return new Email(data);
            case 'EmailRedirection':
                return new EmailRedirection(data);
            case 'Employee':
                return new Employee(data);
            case 'Feature':
                return new Feature(data);
            case 'File':
                return new File(data);
            case 'Invite':
                return new Invite(data);
            case 'InvoiceContact':
                return new InvoiceContact(data);
            case 'InvoiceNumbering':
                return new InvoiceNumbering(data);
            case 'InvoiceOrigin':
                return new InvoiceOrigin(data);
            case 'InvoiceType':
                return new InvoiceType(data);
            case 'Journal':
                return new Journal(data);
            case 'KpiBankAccountBalances':
                return new KpiBankAccountBalances(data);
            case 'KpiBankAccountDailyBalance':
                return new KpiBankAccountDailyBalance(data);
            case 'KpiCharges':
                return new KpiCharges(data);
            case 'KpiMonthSummary':
                return new KpiMonthSummary(data);
            case 'KpiMonthlySummary':
                return new KpiMonthlySummary(data);
            case 'KpiPartnerDebts':
                return new KpiPartnerDebts(data);
            case 'LegalStatus':
                return new LegalStatus(data);
            case 'LegacyPayment':
                return new LegacyPayment(data);
            case 'Loan':
                return new Loan(data);
            case 'NumericComparator':
                return new NumericComparator(data);
            case 'Organization':
                return new Organization(data);
            case 'OrganizationActivity':
                return new OrganizationActivity(data);
            case 'OrganizationIdentifierType':
                return new OrganizationIdentifierType(data);
            case 'OrganizationSize':
                return new OrganizationSize(data);
            case 'Overpayment':
                return new Overpayment(data);
            case 'OverpaymentReimbursement':
                return new OverpaymentReimbursement(data);
            case 'Package':
                return new Package(data);
            case 'PackageContent':
                return new PackageContent(data);
            case 'Partner':
                return new Partner(data);
            case 'PartnerAccountStatement':
                return new PartnerAccountStatement(data);
            case 'PartnerContact':
                return new PartnerContact(data);
            case 'PartnerGroup':
                return new PartnerGroup(data);
            case 'PartnerIdentifier':
                return new PartnerIdentifier(data);
            case 'PartnerType':
                return new PartnerType(data);
            case 'PaymentMethod':
                return new PaymentMethod(data);
            case 'PaymentTerm':
                return new PaymentTerm(data);
            case 'PhoneAreaCode':
                return new PhoneAreaCode(data);
            case 'Pricing':
                return new Pricing(data);
            case 'Product':
                return new Product(data);
            case 'ProductGroup':
                return new ProductGroup(data);
            case 'ProductType':
                return new ProductType(data);
            case 'Profile':
                return new Profile(data);
            case 'PurchaseInvoice':
                return new PurchaseInvoice(data);
            case 'PurchaseInvoiceAllocation':
                return new PurchaseInvoiceAllocation(data);
            case 'PurchaseInvoiceItem':
                return new PurchaseInvoiceItem(data);
            case 'PurchaseInvoiceItemAllocation':
                return new PurchaseInvoiceItemAllocation(data);
            case 'PurchaseInvoicePayment':
                return new PurchaseInvoicePayment(data);
            case 'PurchaseInvoiceTax':
                return new PurchaseInvoiceTax(data);
            case 'Quota':
                return new Quota(data);
            case 'Quotation':
                return new Quotation(data);
            case 'QuotationItem':
                return new QuotationItem(data);
            case 'QuotationTax':
                return new QuotationTax(data);
            case 'SalesDocumentTemplate':
                return new SalesDocumentTemplate(data);
            case 'SalesDocumentType':
                return new SalesDocumentType(data);
            case 'SalesInvoice':
                return new SalesInvoice(data);
            case 'SalesInvoiceAllocation':
                return new SalesInvoiceAllocation(data);
            case 'SalesInvoiceItem':
                return new SalesInvoiceItem(data);
            case 'SalesInvoiceItemAllocation':
                return new SalesInvoiceItemAllocation(data);
            case 'SalesInvoicePayment':
                return new SalesInvoicePayment(data);
            case 'SalesInvoiceTax':
                return new SalesInvoiceTax(data);
            case 'SalesSettings':
                return new SalesSettings(data);
            case 'SellingInfoRecord':
                return new SellingInfoRecord(data);
            case 'Shareholder':
                return new Shareholder(data);
            case 'Subscription':
                return new Subscription(data);
            case 'Tax':
                return new Tax(data);
            case 'TaxDetail':
                return new TaxDetail(data);
            case 'TaxationRegime':
                return new TaxationRegime(data);
            case 'TaxationSettings':
                return new TaxationSettings(data);
            case 'TaxationType':
                return new TaxationType(data);
            case 'TgcDeclaration':
                return new TgcDeclaration(data);
            case 'Timezone':
                return new Timezone(data);
            case 'TransactionDirection':
                return new TransactionDirection(data);
            case 'Unit':
                return new Unit(data);
            case 'User':
                return new User(data);
            case 'UserProfile':
                return new UserProfile(data);
            case 'VatChargeability':
                return new VatChargeability(data);
            case 'VatFrequency':
                return new VatFrequency(data);
            default:
                let message = 'Class ' + className + ' not defined in GeneralFactory'
                console.error(message);
                throw message;
        }
    }
}
