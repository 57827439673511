import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

import { MenuItem, Message } from 'primeng/api';

// Services
import { ClientService } from './client/client.service';
import { ThemeService } from 'src/app/theme.service';

// Classes
import { Client } from 'src/app/@shared/models/client/client.model';
import { User } from 'src/app/@shared/models/user/user.model';

// Utils
import { Logger } from './logger/logger.service';
import { MessageServiceBroker } from './message.service';

const LOGGER = new Logger('AppService');
/**
 * Service de données globales de l'application
 */
@Injectable({
    providedIn: 'root'
})
export class AppService {
    // CONSTANTS
    readonly CREDENTIALS_KEY = 'credentials';

    /*----------------------------------------------------------------------------------------
     * Données du service
     ----------------------------------------------------------------------------------------*/
    // Attributs de l'appareil
    public isLandscape: boolean = false;
    public isDesktop: boolean = false;
    public isPhone: boolean = false;
    public isTablet: boolean = false;

    // Données du serveur
    public serverInfo: any = {};

    // Contexte global
    private _clients: Client[] = [];
    private _selectedClient: Client | null = null;
    private _user: User | null = null;

    // État global de l'application
    public breadcrumbItems: MenuItem[] = []
    public homeBreadcrumbItem: MenuItem = { icon: 'pi pi-home', routerLink: '/home/dashboard' };
    public menuOpen: boolean = true;
    public showNavButton: boolean = false;
    public theme: string = "bfor-light-blue";
    public uiBlocked: boolean = false;

    // Évènements
    private _clientsLoaded$: BehaviorSubject<Client[] | null> = new BehaviorSubject<Client[] | null>(null);
    public readonly clientsLoaded$ = this._clientsLoaded$.asObservable();
    private _clientSelected$: BehaviorSubject<Client | null> = new BehaviorSubject<Client | null>(null);
    public readonly clientSelected$ = this._clientSelected$.asObservable();
    private _userLoaded$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
    public readonly userLoaded$ = this._userLoaded$.asObservable();

    /*----------------------------------------------------------------------------------------
     * Accesseurs
     ----------------------------------------------------------------------------------------*/

    public get logoSrc(): string {
        return `assets/images/logo-${this.theme.includes('light') ? 'light' : 'dark'}.png`;
    }

    public get clients(): Client[] {
        return this._clients;
    }
    public set clients(clients: Client[]) {
        this._clients = clients;
        // Nouvelle valeur pour l'observable des organisations chargée
        this._clientsLoaded$.next(this._clients);
    }

    public get selectedClient(): Client | null {
        return this._selectedClient;
    }
    public set selectedClient(client: Client | null) {
        this.storeClient(client);
        this._selectedClient = client;
        this._clientSelected$.next(client);
        if (!!client) {
            this._loadAuthorizations();
        } else {
            this.user!.authorizations = [];
        }
    }

    public get switchThemeIcon(): string {
        return this.theme.includes('light') ? 'pi-moon' : 'pi-sun';
    }

    public get user(): User | null {
        return this._user;
    }
    public set user(user: User | null) {
        if (!!user && user.id !== this._user?.id) {
            this._user = user;
            this._userLoaded$.next(this._user!);
        } else if (!user) {
            this.reset();
        }
    }

    /*----------------------------------------------------------------------------------------
     * Constructeur
     ----------------------------------------------------------------------------------------*/

    constructor(
        private _router: Router,
        private _clientService: ClientService,
        private _deviceService: DeviceDetectorService,
        private _messageServiceBroker: MessageServiceBroker,
        private _themeService: ThemeService,
    ) {
        // Sélection de l'organisation
        this._clientService.selected$.subscribe((client: Client) => {
            if (!!client) {
                this.selectedClient = client;
            }
        });
        // Type d'appareil
        this.isDesktop = this._deviceService.isDesktop();
        this.isPhone = this._deviceService.isMobile();
        this.isTablet = this._deviceService.isTablet();
        // Définition du theme
        let theme = localStorage.getItem('theme');
        if (!!theme) {
            this.theme = theme;
            this._themeService.switchTheme(theme);
        }
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    public hideNavButton(): void {
        this.showNavButton = false;
    }

    public isFooterDisplayed(): boolean {
        return this.isClientSelected() && this.isPhone;
    }

    public isClientSelected(): boolean {
        return !!this.selectedClient;
    }

    public isPortrait(): boolean {
        return !this.isLandscape;
    }

    public navToDashboard(): void {
        this._router.navigate(['dummy'], {
            skipLocationChange: true
        }).then(() => {
            this._router.navigate(['home/dashboard'])
        })
    }

    /**
     * Ré-initialisation des données de l'application
     */
    public reset(): void {
        this._user = null;
        this._userLoaded$.next(this._user);
        this._clients = [];
        this._clientsLoaded$.next(null);
        this.unselectClient();

        this.breadcrumbItems = [];
        this.menuOpen = true;
        this.showNavButton = false;
        this.theme = "bfor-light-blue";
        this.uiBlocked = false;
    }

    public selectClient(client: Client) {
        if (!this.selectedClient || client.id !== this.selectedClient.id) {
            this.uiBlocked = true;
            this._clientService.get$(client.id).subscribe(() => {
                this.uiBlocked = false;
                this.navToDashboard();
            });
        }
    }

    public showMessage(message: Message): void {
        this._messageServiceBroker.sendMessage(message);
    }

    public switchTheme(): void {
        this.theme = this.theme === 'bfor-light-blue' ? 'bfor-dark-blue' : 'bfor-light-blue';
        this._themeService.switchTheme(this.theme);
        // Sauvegarde du theme dans un cookie
        localStorage.setItem('theme', this.theme);
    }

    public toggleMenu(): void {
        this.menuOpen = !this.menuOpen;
    }

    public unselectClient() {
        this._selectedClient = null;
        this._clientSelected$.next(this._selectedClient);
        this._clientService.unselect();
        // Suppression du token de l'organisation sélectionnée
        LOGGER.debug('Suppression du client de la mémoire locale/session');
        sessionStorage.removeItem('Client');
        localStorage.removeItem('Client');
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    /**
     * Stockage/suppression d'une instance de la mémoire locale/session
     * @param instance Objet à sauvegarder
     * @param selected
     */
    protected storeClient(client: Client | null) {
        if (!!client) {
            LOGGER.debug('Sauvegarde de l\'ID client');
            let storage;
            // Vérification du stockage utilisé
            const credentials = localStorage.getItem(this.CREDENTIALS_KEY);
            if (credentials) {
                storage = localStorage;
            } else {
                storage = sessionStorage;
            }
            storage.setItem('Client', client.id.toString());
        } else {
            LOGGER.debug('Suppression de l\'ID client');
            localStorage.removeItem('Client');
            sessionStorage.removeItem('Client');
        }
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _loadAuthorizations(): void {
        // Récupération des autorisations de l'utilisateur
        this._clientService.getRelated$(this.selectedClient, 'authorizations').subscribe((authorizations: any) => {
            this.userLoaded$.subscribe(user => {
                if (!!user) {
                    user.authorizations = authorizations;
                }
            });
        });
    }
}
