import moment from "moment";
import { ModelBase } from "../abstract/model-base.model";

export class AccountStatementTransaction extends ModelBase {

    static override CLASS_NAME: string = 'AccountStatementTransaction';

    private _amount!: number;
    private _balance!: number;
    private _date!: moment.Moment;
    private _description!: string;
    private _type!: string;

    public get amount(): number {
        return this._amount;
    }

    public set amount(value: any) {
        this._setValue('_amount', value, 'float');
    }

    public get balance(): number {
        return this._balance;
    }

    public set balance(value: any) {
        this._setValue('_balance', value, 'float');
    }

    public get date(): moment.Moment {
        return this._date;
    }

    public set date(value: any) {
        this._setValue('_date', value, 'date');
    }

    public get description(): string {
        return this._description;
    }

    public set description(value: any) {
        this._setValue('_description', value, 'string');
    }

    public get type(): string {
        return this._type;
    }

    public set type(value: any) {
        this._setValue('_type', value, 'string');
    }

}
