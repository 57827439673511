import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { BaseComponent } from 'src/app/@shared/components/abstract/base.component';

@Component({
    selector: 'app-side-menu',
    standalone: true,
    imports: [
        CommonModule,
        TieredMenuModule,
    ],
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent extends BaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    public menuItems: any[] = [];

    private _lockedLinkClass = 'text-gray-300';
    private _showAccountingMenuItems = false;
    private _showBankMenuItems = false;
    private _showPurchasingMenuItems = false;
    private _showSalesMenuItems = false;

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Accesseurs
     ----------------------------------------------------------------------------------------*/

    get showAccountingMenuItems(): boolean {
        let forceOpen = this.router.url.includes('accounting');
        return (forceOpen || this._showAccountingMenuItems) && this.appService.menuOpen;
    }

    get showBankMenuItems(): boolean {
        // Si la route contient 'banking'
        let forceOpen = this.router.url.includes('banking');
        return (forceOpen || this._showBankMenuItems) && this.appService.menuOpen;
    }

    get showPurchasingMenuItems(): boolean {
        let forceOpen = this.router.url.includes('purchasing');
        return (forceOpen || this._showPurchasingMenuItems) && this.appService.menuOpen;
    }

    get showSalesMenuItems(): boolean {
        let forceOpen = this.router.url.includes('sales');
        return (forceOpen || this._showSalesMenuItems) && this.appService.menuOpen;
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    getTooltip(menuItem: any): string {
        return !this.appService.menuOpen ? menuItem.title : '';
    }

    onClickSectionTitle(section: string): void {
        // Si le menu est ouvert
        if (this.appService.menuOpen) {
            switch (section) {
                case 'accounting':
                    this._toggleAccountingMenuItems();
                    break;
                case 'bank':
                    this._toggleBankMenuItems();
                    break;
                case 'purchasing':
                    this._togglePurchasingMenuItems();
                    break;
                case 'sales':
                    this._toggleSalesMenuItems();
                    break;
            }
            this._buildMenu();
        } else {
            // Navigation directe
            switch (section) {
                case 'accounting':
                    this.router.navigate(['/accounting/journals']);
                    break;
                case 'bank':
                    this.router.navigate(['/banking/accounts']);
                    break;
                case 'purchasing':
                    this.router.navigate(['/purchasing/invoices']);
                    break;
                case 'sales':
                    this.router.navigate(['/sales/invoices']);
                    break;
            }
        }
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected override subscribeToObservables(): void {
        this.subscriptions.add(
            this.appService.clientSelected$.subscribe(() => {
                this._buildMenu();
            })
        );
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _buildMenu(): void {
        this.menuItems = [
            {
                section: 'dashboard',
                link: '/home/dashboard',
                title: 'Dashboard',
                icon: 'fa-gauge',
                showSubItems: false,
                ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Dashboard) },
                showSection: true,
                locked: !this.appService.selectedClient?.hasFeature(this.Features.Dashboard)
            },
            {
                section: 'bank',
                link: '/banking/accounts',
                title: 'Banque',
                icon: this.appService.selectedClient?.hasFeature(this.Features.Banking) ? 'fa-building-columns' : 'fa-lock',
                showSubItems: this.showBankMenuItems,
                ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Banking) },
                showSection: true,
                locked: !this.appService.selectedClient?.hasFeature(this.Features.Banking),
                subItems: [
                    {
                        link: '/banking/accounts',
                        title: 'Comptes bancaires',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Banking) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.Banking)
                    },
                    {
                        link: '/banking/cheques',
                        title: 'Chèques',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Cheques) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.Cheques)
                    },
                    {
                        link: '/banking/deposits',
                        title: 'Remises de chèque',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Cheques) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.Cheques)
                    },
                    // {
                    //     link: '/banking/loans',
                    //     title: 'Emprunts',
                    //     ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Loans) },
                    //     locked: !this.appService.selectedClient?.hasFeature(this.Features.Loans)
                    // }
                ]
            },
            {
                section: 'sales',
                link: '/sales/invoices',
                title: 'Ventes',
                icon: this.appService.selectedClient?.hasFeature(this.Features.Banking) ? 'fa-arrow-trend-up' : 'fa-lock',
                showSubItems: this.showSalesMenuItems,
                ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices) },
                showSection: true,
                locked: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices),
                subItems: [
                    {
                        link: '/sales/quotations',
                        title: 'Devis',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.SalesQuotations) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.SalesQuotations)
                    },
                    {
                        link: '/sales/invoices',
                        title: 'Factures',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices)
                    },
                    {
                        link: '/sales/clients',
                        title: 'Clients',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices)
                    },
                    {
                        link: '/sales/products',
                        title: 'Articles',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices)
                    }
                ]
            },
            {
                section: 'purchasing',
                link: '/purchasing/invoices',
                title: 'Achats',
                icon: this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices) ? 'fa-arrow-trend-down' : 'fa-lock',
                showSubItems: this.showPurchasingMenuItems,
                ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices) },
                showSection: true,
                locked: !this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices),
                subItems: [
                    {
                        link: '/purchasing/invoices',
                        title: 'Factures',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices)
                    },
                    {
                        link: '/purchasing/vendors',
                        title: 'Fournisseurs',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices)
                    }
                ]
            },
            {
                section: 'accounting',
                link: '/accounting/journals',
                title: 'Comptabilité',
                icon: this.appService.selectedClient?.hasFeature(this.Features.Accounting) ? 'fa-euro-sign' : 'fa-lock',
                showSubItems: this.showAccountingMenuItems,
                ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Accounting) },
                showSection: true,
                locked: !this.appService.selectedClient?.hasFeature(this.Features.Accounting),
                subItems: [
                    {
                        link: '/accounting/journals',
                        title: 'Journaux',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Accounting) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.Accounting)
                    }
                ]
            },
            {
                section: 'taxation',
                link: '/taxation/tgc-declarations',
                title: `Déclarations de ${this.taxNameLabel}`,
                icon: this.appService.selectedClient?.hasFeature(this.Features.Tgc) ? 'fa-coins' : 'fa-lock',
                showSubItems: false,
                ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Tgc) },
                showSection: !!this.appService.selectedClient?.isCaledonian && !!this.appService.selectedClient?.taxationSettings?.vatSubject,
                locked: !this.appService.selectedClient?.hasFeature(this.Features.Tgc)
            }
        ];
    }

    private _toggleAccountingMenuItems(): void {
        this._showAccountingMenuItems = !this._showAccountingMenuItems;
    }

    private _toggleBankMenuItems(): void {
        this._showBankMenuItems = !this._showBankMenuItems;
    }

    private _togglePurchasingMenuItems(): void {
        this._showPurchasingMenuItems = !this._showPurchasingMenuItems;
    }

    private _toggleSalesMenuItems(): void {
        this._showSalesMenuItems = !this._showSalesMenuItems;
    }
}
