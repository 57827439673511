import { Component } from '@angular/core';

import { PrimeNGConfig } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';

import { ConfirmationServiceBroker } from './@core/services/confirmation.service';
import { DialogServiceHelper } from './@core/services/dialog.service';
import { MessageServiceBroker } from './@core/services/message.service';
import { AppService } from './@core/services/app.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        ConfirmationService,
        DialogService,
        DynamicDialogRef,
        MessageService,
    ],
})
export class AppComponent {

    public appReady: boolean = false;

    constructor(
        private _primengConfig: PrimeNGConfig,
        private _confirmationService: ConfirmationService,
        private _confirmationServiceBroker: ConfirmationServiceBroker,
        private _dialogService: DialogService,
        private _dialogServiceHelper: DialogServiceHelper,
        private _messageService: MessageService,
        private _messageServiceBroker: MessageServiceBroker,
        private _dialogRef: DynamicDialogRef,
        private _appService: AppService,
    ) { }

    ngOnInit() {
        // Activation de l'effet ripple
        this._primengConfig.ripple = true;

        // Souscription générale aux demandes de confirmation de l'application
        this._confirmationServiceBroker.confirmation$.subscribe(confirmation => {
            if (!Object.keys(confirmation).length) return;
            this._confirmationService.confirm(confirmation);
        });

        // Souscription générale aux ouvertures de modales
        this._dialogServiceHelper.dialogConfig$.subscribe(config => {
            // Config vide
            if (!config || !Object.keys(config).length) return;

            this._dialogRef = this._dialogService.open(config.component, config.config);
            this._dialogServiceHelper.setDialogRef(config.config.data.uuid, this._dialogRef);
        });

        // Souscription générale aux message de l'application
        this._messageServiceBroker.messages$.subscribe(messages => {
            this._messageService.addAll(messages);
        });

        // Indiquer que l'application est prête après le chargement des données initiales
        this._appService.clientsLoaded$.subscribe(() => {
            this.appReady = true;
            const loadingDiv = document.getElementById('globalLoading');
            // Suppression de l'élément de chargement
            if (loadingDiv) loadingDiv.remove();
        });
    }
}
