import { AccountStatement } from "../accounting/account-statement.model";
import { Partner } from "./partner.model";

export class PartnerAccountStatement extends AccountStatement {

    static override CLASS_NAME: string = 'PartnerAccountStatement';

    private _partner!: Partner;

    public get partner(): Partner {
        return this._partner;
    }

    public set partner(value: any) {
        this._setValue('_partner', value, 'Partner');
    }

}
