import moment from "moment";
import { ModelBase } from "../abstract/model-base.model";
import { Currency } from "../core/currency.model";
import { AccountStatementTransaction } from "./account-statement-transaction.model";
import { Account } from "./account.model";

export class AccountStatement extends ModelBase {

    static override CLASS_NAME: string = 'AccountStatement';

    private _account!: Account;
    private _dateFrom!: moment.Moment;
    private _dateTo!: moment.Moment;
    private _endingBalance!: number;
    private _currency!: Currency;
    private _startingBalance!: number;
    private _transactions!: AccountStatementTransaction[];

    public get account(): Account {
        return this._account;
    }

    public set account(value: any) {
        this._setValue('_account', value, 'Account');
    }

    public get dateFrom(): moment.Moment {
        return this._dateFrom;
    }

    public set dateFrom(value: any) {
        this._setValue('_dateFrom', value, 'date');
    }

    public get dateTo(): moment.Moment {
        return this._dateTo;
    }

    public set dateTo(value: any) {
        this._setValue('_dateTo', value, 'date');
    }

    public get endingBalance(): number {
        return this._endingBalance;
    }

    public set endingBalance(value: any) {
        this._setValue('_endingBalance', value, 'float');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get startingBalance(): number {
        return this._startingBalance;
    }

    public set startingBalance(value: any) {
        this._setValue('_startingBalance', value, 'float');
    }

    public get transactions(): AccountStatementTransaction[] {
        return this._transactions;
    }

    public set transactions(value: any) {
        this._setValue('_transactions', value, 'AccountStatementTransaction');
    }

}
